import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';
import  Cupones  from './Cupones/Cupones'
import  Resultado  from './Resultado/Resultado'
import  Transacciones  from './Transacciones/Transacciones'
import  TransaccionesGeneral  from './TransaccionesGeneral/TransaccionesGeneral'
import  Detalles  from './Reportes/Detalles/Detalles'
import  Reportes  from './Reportes/Reportes'
import  Reportesscw  from './Reportes/Reportesscw'
import  Login  from './Login/Login'
import { BrowserRouter, Routes, Route, NavLink, Router } from 'react-router-dom';
import PrivateRoute from './Utils/PrivateRoute';

function App() {

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  return (
    <BrowserRouter>
      <div>
        <div className="content">
        <Routes>
          <Route exact path="/" element={<Login/>} />
          {/* <Route element={<PrivateRoute/>}>
            <Route path='/marea' element={<Marea/>}/>
          </Route> */}
          <Route element={<PrivateRoute/>}>
            <Route path='/cupones' element={<Cupones/>}/>
          </Route>
          <Route element={<PrivateRoute/>}>
            <Route exact path="cupones/:identificador" element={<Resultado/>} />
          </Route>
          {/* <Route element={<PrivateRoute/>}>
            <Route exact path="/detalle/:id_cupon" element={<Detalles/>} />
          </Route> */}
          <Route element={<PrivateRoute/>}>
            <Route exact path="/reportes" element={<Reportes/>} />
          </Route>
          <Route element={<PrivateRoute/>}>
            <Route exact path="/transacciones/:identificador" element={<Transacciones/>} />
          </Route>
          <Route element={<PrivateRoute/>}>
            <Route exact path="/transacciones/general" element={<TransaccionesGeneral/>} />
          </Route>
          <Route element={<PrivateRoute/>}>
            <Route exact path="/reportes/cupones" element={<Reportesscw/>} />
          </Route>
          {/* <Route element={<PrivateRoute/>}>
            <Route exact path="/prueba" element={<Prueba/>} />
          </Route> */}
        </Routes>
      </div>
    </div>
  </BrowserRouter>
  );

}

export default App;

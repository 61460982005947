import { useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import '../App.css';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../logo.png';
import loading from '../LTABSAv2.gif';
import './Reportes.css';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import { getUser, removeUserSession } from '../Utils/Common';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { Modal } from 'react-bootstrap';
import Detalles from './Detalles/Detalles'
import show from '../show-white.png'
import hide from '../hide-white.png'

const Verify= () => {

	const navigate = useNavigate();
	
	useEffect(() => {

		if (!getUser()){
		 navigate("/")   
		}
	},[])
	
}

let es = false;
let ne = false;
let ex = false;
let em = false;
let id = '';


const ITEMS_PER_PAGE = 4000;
function Reportes(){
	Verify();

    const [currentPage, setCurrentPage] = useState(1);

    function TableBody() {
        const paginatedResp = sortedResp.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);
        return (
            <>
                {resp ? paginatedResp.map((item, index) => (
                    <tbody className="tblbd">
                        <tr key={item.Cupon}>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign:'center'}}>
                                    {(index+1)+((ITEMS_PER_PAGE*currentPage)-ITEMS_PER_PAGE)}
                            </td>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                    {item.Cupon}
                            </td>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                    {item.Grupo}
                            </td>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'left'}}>
                                {item.Item}
                            </td>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                    {item.Emitido.substr(8,2)+"-"+item.Emitido.substr(5,3)+item.Emitido.substr(0,4)}
                            </td>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                    {item.Utilizado != null ? item.Utilizado.substr(8,2)+"-"+item.Utilizado.substr(5,3)+item.Utilizado.substr(0,4):'-'}
                            </td>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                    {item.Nave}
                            </td>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                    {item.Expirado}
                            </td>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                    {item.Documento}
                            </td>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                    {item.Correlativo}
                            </td>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                    {item["Código Autorización"]}
                            </td>
                            {resp.length > 0 ?
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                {isOpen?
                                    <>
                                            <Detalles neto={item.Neto} total={item.Total} iva={item.IVA}></Detalles>
                                            <br/>
                                    </>
                                    :
                                '...'}
                            </td>
                            :''}
                        </tr>
                    </tbody>
                )) : ''}

            </>
        );
    }

	const navigate = useNavigate();

    const cookies = new Cookies();

    const handleLogout = () => {
        cookies.remove('user', {path: "/"})	
        removeUserSession();
        navigate('/');
    }

    const [esChecked, setEsChecked] = useState(false);
    const [neChecked, setNeChecked] = useState(false);
    const [exChecked, setExChecked] = useState(false);
    const [paginationInfo, setPaginationInfo] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState();
    const [isCheckedID, setIsCheckedID] = useState(false);
    const [isCheckedEm, setIsCheckedEm] = useState(false);
    const [isCheckedEs, setIsCheckedEs] = useState(false);
    const [isCheckedNe, setIsCheckedNe] = useState(false);
    const [isCheckedEx, setIsCheckedEx] = useState(false);
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleEsChange = (event) => {
        setEsChecked(event.target.checked);
        // Si 'es' está seleccionado, desmarca 'ne' y 'ex'
        if (event.target.checked) {
            setNeChecked(false);
            setExChecked(false);
        }
    };
    const handleNeChange = (event) => {
        setNeChecked(event.target.checked);
        if (event.target.checked) {
            setEsChecked(false);
            setExChecked(false);
        }
    };
    
    const handleExChange = (event) => {
        setExChecked(event.target.checked);
        if (event.target.checked) {
            setEsChecked(false);
            setNeChecked(false);
        }
    };

    const handleCheckboxChange2 = (event) => {
        setIsCheckedEm(event.target.checked);
    };

    const [selectedValue, setSelectedValue] = useState('');
    const [selectedOtros, setSelectedOtros] = useState('0');
    const [id_doc, setIdDoc] = useState(false);
    const [showOtros, setShowOtros] = useState('');
    const [showDocs, setShowDocs] = useState(false);

    // const [startDateI, setStartDateI] = useState(new Date('2023/08/29'));
    const [startDateI, setStartDateI] = useState(() => {
        let date = new Date();
        date.setDate(date.getDate() - 1);
        return date;
    });
    let [startDateFilter_, setStartDateFilter_] = useState(new Date());
    startDateFilter_.setMonth(startDateFilter_.getMonth() -1)
    const [startDateF, setStartDateF] = useState(new Date());
    const now = useState(new Date());
    const { identificador } = useParams()
    const url = 'https://api-preventa.tabsa.cl/api/filtro/cupones'
    // const [resp, setResp] = useState([]);
    const [Fe, setFe] = useState(false);
    var filtro = 0;
    var newArreglo = []
    var item_data = 0
    const [dataForDownload, setDataForDownload] = useState([]);
    const [bDownloadReady, setDownloadReady] = useState(false);
    const [cant_resultados, setCantResultados] = useState(false);
    const csvLink = useRef();

    function handleChangeID(value){
        id = value
        setIsChecked(false)
        setIsCheckedEm(false)
        setIsCheckedEs(false)
        setIsCheckedEx(false)
        setIsCheckedNe(false)
        if(value != ''){
            setIsCheckedID(true)
        }else{
            setIsCheckedID(false)
        }
        // console.log('value id_cupon')
        // console.log(value)
    }
    const url_doc = 'https://api-documento.tabsa.cl/api/documento/tipo'
    const [Docs, setDocs] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
          const result = await axios(url_doc);
          setDocs(result.data);
        };
    
        fetchData();
      }, []);

      
    // if(Jo == false){
    //     axios.get(url_doc).then(
    //         response => {
    //             const tipo_docs = response.data
    //             setDocs(tipo_docs)
    //             console.log('respuesta docs')
    //             console.log(tipo_docs)
    //             console.log(Docs)
    //         }
    //     )
    //     setJo(true)
    // }
    const [detailsShown, setDetailsShown] = useState(false);
    const toggleDetailsVisiblity = () => {
      setDetailsShown(!detailsShown);
    };

    const [resp, setResp] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });
    const sortedResp = [...resp].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });
    const handleSort = (key) => {
        // console.log('keyyyy')
        // console.log(key)
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
          direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    function filtrarCupones(){

        if(!es && !ne && !ex) filtro = 0
        if(es && ne && ex) filtro = 1
        if(es && !ne && !ex) filtro = 2
        if(!es && ne && !ex) filtro = 3
        if(!es && !ne && ex) filtro = 4
        if(es && ne && !ex) filtro = 5
        if(es && !ne && ex) filtro = 6
        if(!es && ne && ex) filtro = 7
        if(em) filtro = 8
        if(id != '' && !es && !ne && !ex){
            filtro = selectedOtros == "Documento" ? 11 : (selectedOtros == "ID Cupón" ? 9 : (selectedOtros == "Código Autorización" ? 12 : 10)) 
        } 
        const data = {
            'filtro': filtro,
            'fecha_inicial': startDateI,
            'fecha_final': startDateF,
            'id_nave': isChecked && selectedValue != 50 ? selectedValue : null,
            'code': id,
            'id_tipo_doc': id_doc
        } 
        setLoading(true)
        axios.post(url, data).then(
            response => {
                const respuesta = response.data
                console.log('ladata')
                console.log(respuesta)

                setResp(respuesta.cupones)
                setPaginationInfo(respuesta.datos_paginacion)
                setFe(true)
                setLoading(false)
            }
        )
    }
    
    const handleSelectIdDoc = (event) => {
        setIdDoc(event.target.value);
    }

    function handleChange(value){
        em = false

        if(value === 'es'){
            if(es === true){
                es = false
                setIsCheckedEs(false) 
            }else{
                es = true
                setIsCheckedEs(true)
                setIsCheckedNe(false) 
                setIsCheckedEx(false) 
            } 
        } 
        if(value === 'ne'){
            if(ne === true){
                ne = false
                setIsCheckedNe(false) 
            }else{
                ne = true
                setIsCheckedNe(true)
                setIsCheckedEs(false)
                setIsCheckedEx(false) 
            } 
        } 
        if(value === 'ex'){
            if(ex === true){
                ex = false
                setIsCheckedEx(false) 
            }else{
                ex = true
                setIsCheckedEx(true)
                setIsCheckedEs(false) 
                setIsCheckedNe(false) 
            } 
        } 

        if(es && !ne && !ex){
            setIsChecked(true);
        }else{
            setIsChecked(false);
        }

        if(es || ne || ex){
            setIsCheckedEm(false)
            setIsCheckedID(false)
        }else{
            if(id != ''){
                handleChangeID(id)
            }
        }
    }

    function redirectTransaccionesGeneral(){
        navigate('/transacciones/general')
    }

    const [isOpen, setIsOpen] = useState(false);
    
    const abrirModal = (it) => {
        setIsOpen(!isOpen);
        item_data = it

    };

    function handleChangeEm(value){
        if(value === 'em') em = em ? false : true
        if(em === true){
            setIsCheckedEm(true);
            setIsChecked(false)
            setIsCheckedEs(false)
            setIsCheckedNe(false)
            setIsCheckedEx(false)
            setIsCheckedID(false)
            es = false
            ne = false
            ex = false
        }else{
            setIsCheckedEm(false);
        }
    }

    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const handleSelectOtros = (event) => {
        setSelectedOtros(event.target.value);
        // console.log('target value')
        // console.log(event.target.value)
        if(event.target.value != 0){
            setIsCheckedEm(false);
            setIsChecked(false)
            setIsCheckedEs(false)
            setIsCheckedNe(false)
            setIsCheckedEx(false)

            em = false;
            es = false
            ne = false
            ex = false
            // setIsCheckedID(false)
            setShowOtros(true)
            if(event.target.value == "Documento"){
                setShowDocs(true)
            }else{
                setShowDocs(false)
            }
        }else{
            setShowOtros(false)
            setShowDocs(false)
            id = ''
        }
    };

    useEffect(() => {
        if (csvLink.current && bDownloadReady) {
          csvLink.current.link.click();
          setDownloadReady(false);
        }
      }, [bDownloadReady]);
    
      // start the download process
      const handleDownload = () => {
        setDataForDownload(resp);
        setDownloadReady(true);
      };


    return (
        <>
            <header>
                <img src={logo} className="logo"></img>
                <input type="button" className='button2' style={{float: 'right'}} onClick={handleLogout} value="Salir" />
            </header>
            <body>

                
                <center>
                    
                <h1>Reportes</h1>
                <input type="button" className='button' id="transacciones" style={{marginLeft: '65%', marginTop: '-380px'}} onClick={redirectTransaccionesGeneral} value="Transacciones General" />
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <table>
                        <thead>
                            <tr style={{color: 'white', borderColor: '0px !important'}}>
                                <th style={{textAlign: 'center', minWidth: '120px', maxWidth: '150px'}}>Fecha Inicio</th>
                                <th style={{textAlign: 'center', minWidth: '120px', maxWidth: '150px'}}>Fecha Fin</th>
                                <th style={{textAlign: 'center', minWidth: '120px', maxWidth: '150px'}} hidden={showOtros}>Emitidos</th>
                                <th style={{textAlign: 'center', minWidth: '120px', maxWidth: '150px'}} hidden={showOtros}>Escaneados</th>
                                <th style={{textAlign: 'center', minWidth: '120px', maxWidth: '150px'}} hidden={!isChecked}>Nave</th>
                                <th style={{textAlign: 'center', minWidth: '120px', maxWidth: '150px'}} hidden={showOtros}>No Escaneados</th>
                                <th style={{textAlign: 'center', minWidth: '120px', maxWidth: '150px'}} hidden={showOtros}>Expirados</th>
                                <th style={{textAlign: 'center', minWidth: '120px', maxWidth: '150px'}}>Otros</th>
                                <th style={{textAlign: 'center', minWidth: '120px', maxWidth: '150px'}} hidden={!showDocs}>Documento</th>
                                <th style={{textAlign: 'center', minWidth: '120px', maxWidth: '150px'}} hidden={!showOtros}>{selectedOtros != "Documento" ? selectedOtros : "Nº Documento"}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{color: 'white', borderColor: '0px !important'}}>
                                <td style={{textAlign: 'center', minWidth: '120px'}}>
                                    <DatePicker selected={startDateI} 
                                                onChange={(date) => setStartDateI(date)}   
                                                type="text"
                                                size="sm"
                                                placeholder="Fecha Inicio"
                                                disabled={isCheckedID}
                                                minDate={new Date("08-29-2023")}
                                                maxDate={startDateF}
                                                dateFormat="dd/MM/yyyy"
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }} 
                                    />
                                </td>
                                <td style={{textAlign: 'center', minWidth: '120px'}}>
                                    <DatePicker selected={startDateF} 
                                                onChange={(date) => setStartDateF(date)}   
                                                type="text"
                                                size="sm"
                                                placeholder="Fecha Fin"
                                                disabled={isCheckedID}
                                                minDate={startDateI}
                                                maxDate={new Date()}
                                                dateFormat="dd/MM/yyyy"
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }} 
                                    />
                                </td>
                                <td hidden={showOtros} style={{textAlign:'center', minWidth: '120px'}}><input style={{transform: 'scale(1.7)', minHeight: '15px', paddingTop:'10px'}} type="checkbox" id="topping" name="topping" checked={isCheckedEm} onChange={event => handleChangeEm('em')}/></td>
                                <td hidden={showOtros} style={{textAlign:'center', minWidth: '120px'}}><input style={{transform: 'scale(1.7)', minHeight: '15px', paddingTop:'10px'}} type="checkbox" id="topping" name="topping" checked={isCheckedEs} onChange={event => handleChange('es')}/></td>
                                <td style={{textAlign: 'center', minWidth: '120px'}} hidden={!isChecked}>
                                    <select style={{borderRadius: '3px', height: '20px'}} value={selectedValue} onChange={handleSelectChange}>
                                        <option value="0">Todas</option>
                                        <option value="11">Kawéskar</option>
                                        <option value="13">Kenos</option>
                                        <option value="1">Patagonia</option>
                                        <option value="8">Pionero</option>
                                    </select>
                                </td>
                                <td hidden={showOtros} style={{textAlign: 'center', minWidth: '120px'}}><input style={{transform:'scale(1.7)', minHeight:'15px', paddingTop:'10px'}} type="checkbox" id="topping" name="topping" checked={isCheckedNe} onChange={event => handleChange('ne')}/></td>
                                <td hidden={showOtros} style={{textAlign: 'center', minWidth: '120px'}}><input style={{transform:'scale(1.7)', minHeight:'15px', paddingTop:'10px'}} type="checkbox" id="topping" name="topping" checked={isCheckedEx} onChange={event => handleChange('ex')}/></td>
                                <td style={{textAlign: 'center', minWidth: '120px'}}>
                                    <select style={{borderRadius: '3px', height: '20px'}} value={selectedOtros} onChange={handleSelectOtros}>
                                        <option value="">Ninguno</option>
                                        <option value="ID Cupón">ID Cupón</option>
                                        <option value="Documento">Documento</option>
                                        <option value="ID Grupo / Reserva">ID Grupo / Reserva</option>
                                        <option value="Código Autorización">Código Autorización</option>
                                    </select>
                                </td>
                                <td style={{textAlign: 'center', minWidth: '120px'}} hidden={!showDocs}>
                                    <select style={{borderRadius: '3px', height: '20px'}} value={id_doc} onChange={handleSelectIdDoc}>
                                    {Docs.map((item, index) => (
                                        <option key={index} value={item.id_tipo_doc}>
                                        {item.nombre_tipo_doc}
                                        </option>
                                    ))}
                                        <option value="666">Todos</option>
                                    </select>
                                </td>
                                <td style={{textAlign: 'center', minWidth: '120px'}}><input type="number" min="1" style={{decoratio:'none'}} id={id} name={id} disabled={isChecked || isCheckedEm || isCheckedEs || isCheckedEx || isCheckedNe}  hidden={!showOtros} onKeyUp={event => handleChangeID(event.target.value)}/></td>
                                <td style={{textAlign:'center'}}>&nbsp;
                                    <button style={{backgroundColor: 'white', borderRadius: '5px',textDecoration: 'none', color:'black'}} hidden={!isChecked && !isCheckedEm && !isCheckedEs && !isCheckedEx && !isCheckedNe && !isCheckedID} onClick={filtrarCupones} >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                            </svg>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {isLoading ? 
                        <center>
                        <img src={loading} style={{width:"20%", marginTop: "300px", backgroundColor: "white", borderRadius:"15px"}} ></img>
                        </center>
                    : <>
                    { Fe == true ?
                     resp.length == 0 ?
                        <h3 style={{color:"white"}}>Sin resultados</h3> : (resp.length > 1 ? <h3 style={{color:"white"}}>{paginationInfo.total_registros} resultados</h3> : <h3 style={{color:"white"}}>{resp.length} resultado</h3>)
                    :''}
                    { Fe == true ?
                        <>
                        <h4 style={{color:"white"}}>{currentPage*ITEMS_PER_PAGE-(ITEMS_PER_PAGE-1)} - { resp.length >= (ITEMS_PER_PAGE * currentPage) ? ITEMS_PER_PAGE * currentPage : resp.length}</h4>
                        <div >
                            <CSVLink
                                data={dataForDownload}
                                filename={'reporte '+format(new Date(),'yyyy-MM-dd')+'.csv'}
                                className="hidden"
                                separator={';'}
                                ref={csvLink}
                                target="_blank"
                            >
                                <div style={{width: "50%",float:"right", marginRight:"-50px", marginTop:"-45px"}}>
                                   <button className='excel' style={{}}> 
                                        <a href="javascript:void(0);" onClick={handleDownload} style={{textDecoration: 'none', color: 'black'}}>
                                            Descargar
                                        </a>
                                    </button>
                                </div>

                            </CSVLink>
                        </div>
                    <br/><br/><br/><br/>
                    <table className='tbl'>
                        <thead>
                            <tr style={{color:'white'}}>
                                <th style={{fontSize: "14px",textAlign:'center', width:'auto', userSelect: 'none'}}>#</th>
                                <th style={{fontSize: "14px",textAlign:'center', minWidth:'70px', maxWidth:'100px', userSelect: 'none'}}>Cupón </th>
                                <th style={{fontSize: "14px",textAlign:'center', minWidth:'70px', maxWidth:'100px', userSelect: 'none'}}>Grupo </th>
                                <th onClick={() => handleSort('Item')} style={{fontSize: "14px",textAlign:'center', minWidth:'auto', maxWidth:'490px', userSelect: 'none'}}>Ítem {resp.length > 0 ? <u>⇅</u>:''}</th>
                                <th onClick={() => handleSort('Emitido')} style={{fontSize: "14px",textAlign:'center', minWidth:'auto', maxWidth:'150px', userSelect: 'none'}}>Emitido {resp.length > 0 ? <u>⇅</u>:''}</th>
                                <th onClick={() => handleSort('Utilizado')} style={{fontSize: "14px",textAlign:'center', minWidth:'auto', maxWidth:'150px', userSelect: 'none'}}>Utilizado {resp.length > 0 ? <u>⇅</u>:''}</th>
                                <th onClick={() => handleSort('Nave')} style={{fontSize: "14px",textAlign:'center', minWidth:'100px', maxWidth:'130px', userSelect: 'none'}}>Nave {resp.length > 0 ? <u>⇅</u>:''}</th>
                                <th onClick={() => handleSort('Expirado')} style={{fontSize: "14px",textAlign:'center', minWidth:'auto', maxWidth:'150px', userSelect: 'none'}}>Expirado {resp.length > 0 ? <u>⇅</u>:''}</th>
                                <th onClick={() => handleSort('Documento')} style={{fontSize: "14px",textAlign:'center', minWidth:'auto', maxWidth:'170px', userSelect: 'none'}}>Documento {resp.length > 0 ? <u>⇅</u>:''}</th>
                                <th onClick={() => handleSort('Documento')} style={{fontSize: "14px",textAlign:'center', minWidth:'auto', maxWidth:'170px', userSelect: 'none'}}>Correlativo {resp.length > 0 ? <u>⇅</u>:''}</th>
                                <th style={{fontSize: "14px",textAlign:'center', minWidth:'auto', maxWidth:'170px', userSelect: 'none'}}>Código Autorización</th>
                                <th onClick={() => abrirModal('')} style={{fontSize: "14px",textAlign:'center', minWidth:'100px', maxWidth:'260px', userSelect: 'none'}}>Valores {resp.length > 0 ? <u onClick={toggleDetailsVisiblity}>{detailsShown ? 'x' : '✓'}</u>:''}</th>
                            </tr>
                        </thead>
                        <TableBody/>
                    </table>
                    </>

                    :''}
                    {resp.length > 0 ? 

                        <center>
                            <br/>
                            <div style={{color: "white"}}>
                            <button onClick={() => setCurrentPage(paginationInfo.pagina_actual - 1)} disabled={paginationInfo.pagina_anterior_url === null}>
                                Anterior
                            </button>
                            &nbsp;&nbsp;{paginationInfo.pagina_actual} / {paginationInfo.total_paginas}&nbsp;&nbsp;
                            <button onClick={() => setCurrentPage(paginationInfo.pagina_actual + 1)} disabled={paginationInfo.siguiente_pagina_url === null}>
                                Siguiente
                            </button>
                            </div>
                        </center>
                    :''}
                    <br/>
                    </>}
                </center>
                <br/>
            </body>
        </>
    )
}



function copyContent(url){
    navigator.clipboard.writeText(url)
    toast.success('Código copiado.', {
        position: toast.POSITION.BOTTOM_CENTER
    })
}
const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
  
    const handleChange = e => {
      setValue(e.target.value);
    }
    return {
      value,
      onChange: handleChange
    }
}

export default Reportes


import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'


const  Detalles = (props) => {

    // let data = axios.
    const unitario = props.neto
    const total = props.total
    const iva = props.iva
	return (
        <div >
            <label style={{float:'left', marginLeft:'10px'}}>Neto:</label> <label style={{float:'right', marginRight:'10px'}}> ${unitario}</label>
            <br/>
            <label style={{float:'left', marginLeft:'10px'}}>IVA:</label> <label style={{float:'right', marginRight:'10px'}}> ${iva}</label>
            <br/>
            <label style={{float:'left', marginLeft:'10px'}}>Total:</label> <label style={{float:'right', marginRight:'10px'}}> ${total}</label>
        </div>
	)
}

export default Detalles
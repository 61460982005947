import logo from '../logo.png';
import './Cupones.css';
import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, NavLink, Router } from 'react-router-dom';
import '../App.css';
import { useNavigate } from "react-router-dom";
import logo_ from '../logo.png'
import Cookies from 'universal-cookie';
import { getUser, removeUserSession } from '../Utils/Common';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Verify= () => {

	const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
	useEffect(() => {
		if (!getUser()){
		 navigate("/")   
		}
	},[])
	
}

const cookies = new Cookies();

function Cupones() {
	Verify();
  const [filtro, setFiltro] = useState(1);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const identificador = useFormInput('');

  const url = 'https://api-preventa.tabsa.cl/api/cupones/filtro'

	const user = sessionStorage.getItem('user')

	const navigate = useNavigate();

  const cookies = new Cookies();

  const handleLogout = () => {
      cookies.remove('user', {path: "/"})	
      removeUserSession();
      navigate('/');
  }

  const handleCupones = (key) => {
    if(identificador.value == '' && key == 'Enter'){
      toast.error('Ingrese un documento válido.', {
      position: toast.POSITION.BOTTOM_CENTER
      });
    }else{
      if(key == 'Enter' || !key){
        
        const data = {
          value: identificador.value,
          filtro: filtro
        }
        
        const headers = { 
        };
        axios.post(url, data).then(
          response => { 
            // console.log(response)
            if(response.data.data != null){
              // console.log('xdddddd')
              // console.log(response.data.identificador)
              navigate('/cupones/'+response.data.identificador);
            }else{
              toast.error('No hay resultados para esta búsqueda.', {
                position: toast.POSITION.BOTTOM_CENTER
              });
            }
          })
        }
      }
    }
      
    return (
      <>
        <header>
          <span>
            <img src={logo} className="logo"></img>
            <input type="button" className='button2' style={{float: 'right'}} onClick={handleLogout} value="Salir" />
          </span>
        </header>
        <body className='contenido' >
          <center>
              <h1 style={{color: 'white', textAlign: 'center', marginTop: '80px'}}>Buscar Cupones</h1>
              <div className='container'>
                <div className='form_' style={{color:"white"}}>
                  {/* <h5>Documento (RUT / Pasaporte)</h5><br /><br /> */}
                <div style={{color: 'white'}}>
                  <input type="radio" id="documento" name="filtro" value="1" checked={filtro === 1} onChange={(e) => setFiltro(Number(e.target.value))} />
                  <label for="documento">Documento (RUT/PASAPORTE)</label><br />
                  <br/>
                  <input type="radio" id="boleta" name="filtro" value="2" checked={filtro === 2} onChange={(e) => setFiltro(Number(e.target.value))} />
                  <label for="boleta">Nº Boleta/Factura</label>
                  <br/><br/>
                </div>
                  <input type="text" className='form' onKeyDown={e => {handleCupones(e.key)}} {...identificador}  required/>
                </div>
                {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
                <input type="button" className='button' value={loading ? 'Buscando...' : 'Buscar'} onClick={e => {handleCupones('Enter')}} disabled={loading} /><br />

  
                <ToastContainer />
            </div>
          </center>
        </body>
      </>
    );
  }

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
  
    const handleChange = e => {
      setValue(e.target.value);
    }
    return {
      value,
      onChange: handleChange
    }
  }

export default Cupones;

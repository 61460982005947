import { useParams, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import '../App.css';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../logo.png';
import loading from '../LTABSAv2.gif';
import './Transacciones.css';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import { getUser, removeUserSession } from '../Utils/Common';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Verify= () => {

	const navigate = useNavigate();
	
	useEffect(() => {

		if (!getUser()){
		    navigate("/")   
		}else if(sessionStorage.getItem('tipoUsuario') < 3){
            navigate(-1)   
        }
        
	},[])
	
}

const ITEMS_PER_PAGE = 20;
function Transacciones(){
	Verify();
    const location = useLocation();

    useEffect(() => {
        const data = {
            grupos: location.state.grupos
        }
        axios.post('https://api-preventa.tabsa.cl/api/historial/transacciones', data.grupos).then(
            response => {
                const respuesta = response.data.data
                setResp(respuesta)
                setFe(true)
                setLoading(false)
            }
        )
	},[])

    const [currentPage, setCurrentPage] = useState(1);

    function TableBody() {
        const paginatedResp = sortedResp.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);
        return (
            <>
                {resp ? paginatedResp.map((item, index) => (
                    <tbody className="tblbd">
                        <tr key={item.id_reserva}>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', extAlign:'center'}}>
                                    {(index+1)+((ITEMS_PER_PAGE*currentPage)-ITEMS_PER_PAGE)}
                            </td>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                    {item.id_reserva}
                            </td>
                            {/* <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                    {item.transactionDate}
                            </td> */}
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                    {item.initTimestamp}
                            </td>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                    ${item.amount}
                            </td>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                    {item.responseCode}
                            </td>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                    {item.cardNumber}
                            </td>
                        </tr>
                    </tbody>
                )) : ''}

            </>
        );
    }

	const navigate = useNavigate();

    const cookies = new Cookies();

    const handleLogout = () => {
        cookies.remove('user', {path: "/"})	
        removeUserSession();
        navigate('/');
    }
    const [isLoading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState('');
    let [startDateFilter_, setStartDateFilter_] = useState(new Date());
    startDateFilter_.setMonth(startDateFilter_.getMonth() -1)
    // const [resp, setResp] = useState([]);
    const [Fe, setFe] = useState(false);



    const [detailsShown, setDetailsShown] = useState(false);
    const toggleDetailsVisiblity = () => {
      setDetailsShown(!detailsShown);
    };

    const [resp, setResp] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });
    const sortedResp = [...resp].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });
    const handleSort = (key) => {
        // console.log('keyyyy')
        // console.log(key)
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
          direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (
        <>
            <header>
                <img src={logo} className="logo"></img>
                <input type="button" className='button2' style={{float: 'right'}} onClick={handleLogout} value="Salir" />
            </header>
            <body>
                <center>
                <h1>Reporte Transacciones</h1>
         
                    {isLoading ? 
                        <>
                            <center>
                            <img src={loading} style={{width:"20%", marginTop: "200px", backgroundColor: "white", borderRadius:"15px"}} ></img>
                            </center>
                        </> : <>
                    { Fe == true ?
                     resp.length == 0 ?
                        <h3 style={{color:"white"}}>Sin resultados</h3> : (resp.length > 1 ? <h3 style={{color:"white"}}>{resp.length} resultados</h3> : <h3 style={{color:"white"}}>{resp.length} resultado</h3>)
                    :''}
                    { Fe == true ?
                        <>
                        <h4 style={{color:"white"}}>{currentPage*ITEMS_PER_PAGE-(ITEMS_PER_PAGE-1)} - { resp.length >= (ITEMS_PER_PAGE * currentPage) ? ITEMS_PER_PAGE * currentPage : resp.length}</h4>
                    <br/><br/><br/><br/>
                    <table className='tbl'>
                        <thead>
                            <tr style={{color:'white'}}>
                                <th style={{fontSize: "14px",textAlign:'center', width:'auto', userSelect: 'none'}}>#</th>
                                <th style={{fontSize: "14px",textAlign:'center', minWidth:'70px', maxWidth:'100px', userSelect: 'none'}}>Grupo </th>
                                {/* <th onClick={() => handleSort('transactionDate')} style={{fontSize: "14px",textAlign:'center', minWidth:'auto', maxWidth:'490px', userSelect: 'none'}}>Inicio Transacción {resp.length > 0 ? <u>⇅</u>:''}</th> */}
                                <th onClick={() => handleSort('initTimestamp')} style={{fontSize: "14px",textAlign:'center', minWidth:'auto', maxWidth:'150px', userSelect: 'none'}}>Fecha Transacción {resp.length > 0 ? <u>⇅</u>:''}</th>
                                <th onClick={() => handleSort('amount')} style={{fontSize: "14px",textAlign:'center', minWidth:'auto', maxWidth:'150px', userSelect: 'none'}}>Monto {resp.length > 0 ? <u>⇅</u>:''}</th>
                                <th onClick={() => handleSort('responseCode')} style={{fontSize: "14px",textAlign:'center', minWidth:'100px', maxWidth:'130px', userSelect: 'none'}}>Respuesta {resp.length > 0 ? <u>⇅</u>:''}</th>
                                <th onClick={() => handleSort('cardNumber')} style={{fontSize: "14px",textAlign:'center', minWidth:'auto', maxWidth:'150px', userSelect: 'none'}}>Nº Tarjeta {resp.length > 0 ? <u>⇅</u>:''}</th>
                            </tr>
                        </thead>
                        <TableBody/>
                    </table>
                    </>

                    :''}
                    {resp.length > 0 ? 

                        <center>
                            <br/>
                            <div style={{color: "white"}}>
                                <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                                    Anterior
                                </button>
                                &nbsp;&nbsp;{currentPage} / {Math.ceil(resp.length/ITEMS_PER_PAGE)}&nbsp;&nbsp;
                                <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(sortedResp.length / ITEMS_PER_PAGE)}>
                                    Siguiente
                                </button>
                            </div>
                        </center>
                    :''}
                    <br/>
                    </>}
                </center>
                <br/>
            </body>
        </>
    )
}



function copyContent(url){
    navigator.clipboard.writeText(url)
    toast.success('Código copiado.', {
        position: toast.POSITION.BOTTOM_CENTER
    })
}
const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
  
    const handleChange = e => {
      setValue(e.target.value);
    }
    return {
      value,
      onChange: handleChange
    }
}

export default Transacciones
import { useParams, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../logo.png';
import spin from '../spin.gif';
import loading from '../LTABSAv2.gif';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import { getUser, removeUserSession } from '../Utils/Common';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { Modal } from 'react-bootstrap';
import './TransaccionesGeneral.css';

const Verify= () => {

	const navigate = useNavigate();
	
	useEffect(() => {

		if (!getUser()){
		    navigate("/")   
		}else if(sessionStorage.getItem('tipoUsuario') < 3){
            navigate(-1)   
        }
        
	},[])
}

let filtro = 1;
let t = true;
let e = false;
let sd = false;
let r = false;
let id = false;
let idc = false;
let s = false;


function TransaccionesGeneral(){
	Verify();

    const navigate = useNavigate();

    const cookies = new Cookies();

    const handleLogout = () => {
        cookies.remove('user', {path: "/"})	
        removeUserSession();
        navigate('/');
    }

    function filtrarCupones(){

        if(isCheckedID && (id == false || id == '')){
            toast.error('Ingresar un valor válido.', {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }else if(!isCheckedID && !isCheckedE && !isCheckedR && !isCheckedSD && !isCheckedT){
            toast.error('Seleccionar un filtro.', {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }else{
            fetchData()
        }
    }

    const now = useState(new Date());
    const [isCheckedT, setIsCheckedT] = useState(true);
    const [isCheckedE, setIsCheckedE] = useState(false);
    const [isCheckedSD, setIsCheckedSD] = useState(false);
    const [isCheckedR, setIsCheckedR] = useState(false);
    const [isCheckedID, setIsCheckedID] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingCupon, setLoadingCupon] = useState(false);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [resp, setResp] = useState([]);
    
    const [dataForDownload, setDataForDownload] = useState([]);
    const [bDownloadReady, setDownloadReady] = useState(false);
    const [nf, setNF] = useState(false);
    const csvLink = useRef();
    const [diaCero, setDiaCero] = useState(new Date('2023/08/29'));
    const [startDateI, setStartDateI] = useState(() => {
        let date = new Date();
        date.setDate(date.getDate() - 1);
        return date;
    });
    let [startDateFilter_, setStartDateFilter_] = useState(new Date());
    startDateFilter_.setMonth(startDateFilter_.getMonth() -1)
    const [startDateF, setStartDateF] = useState(new Date());
    
    const fetchData = async (page = 1) => {
        s = true
        const json = {
            'filtro': filtro,
            'paginate': 20,
            'fecha_inicial': startDateI,
            'fecha_final': startDateF,
            'id': id
        }
        const resp = await axios.post(`https://api-preventa.tabsa.cl/api/historial/transacciones/general?page=${page}`,json);
        console.log('respuestoski');
        console.log(resp);
        setData(resp.data.data);
        setNF(resp.data.nf == false ? false : resp.data.nf)
        setPagination(resp.data.pagination);
        console.log('nf')
        console.log(nf)
    };


    function handleChange(value){
        id = ''
        if(value === 't'){
            if(t === true){
                t = false
                setIsCheckedT(false) 
            }else{
                t = true
                setIsCheckedT(true)
                e = false
                setIsCheckedE(false)
                sd = false
                setIsCheckedSD(false)
                r = false
                setIsCheckedR(false)
                idc = false
                setIsCheckedID(false)
            } 
        } 
        if(value === 'e'){
            if(e === true){
                e = false
                setIsCheckedE(false) 
            }else{
                e = true
                setIsCheckedE(true)
                t = false
                setIsCheckedT(false)
                sd = false
                setIsCheckedSD(false)
                r = false
                setIsCheckedR(false)
                idc = false
                setIsCheckedID(false)
            } 
        } 
        if(value === 'sd'){
            if(sd === true){
                sd = false
                setIsCheckedSD(false) 
            }else{
                sd = true
                setIsCheckedSD(true)
                t = false
                setIsCheckedT(false)
                e = false
                setIsCheckedE(false)
                r = false
                setIsCheckedR(false)
                idc = false
                setIsCheckedID(false)
            } 
        } 
        if(value === 'r'){
            if(r === true){
                r = false
                setIsCheckedR(false) 
            }else{
                r = true
                setIsCheckedR(true)
                t = false
                setIsCheckedT(false)
                e = false
                setIsCheckedE(false)
                sd = false
                setIsCheckedSD(false)
                idc = false
                setIsCheckedID(false)
            } 
        } 
        if(value === 'idc'){
            if(idc === true){
                idc = false
                setIsCheckedID(false) 
            }else{
                idc = true
                setIsCheckedID(true)
                t = false
                setIsCheckedT(false)
                e = false
                setIsCheckedE(false)
                sd = false
                setIsCheckedSD(false)
                r = false
                setIsCheckedR(false)
            } 
        } 
        filtro = (t ? 1 : (e ? 2 : (sd ? 3 : (r ? 4 : 5))))
    }

    function handleChangeID(value){
        id = value
        setIsCheckedID(true)
        setIsCheckedT(false)
        setIsCheckedR(false)
        setIsCheckedSD(false)
        setIsCheckedE(false)

    }

    const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });
    const sortedResp = [...data].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });
    
    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
          direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    useEffect(() => {
        if (csvLink.current && bDownloadReady) {
            csvLink.current.link.click();
            setDownloadReady(false);
          }
          // fetchData();
    }, [bDownloadReady]);
      
        // start the download process
    function handleDownload(){
        const json_ = {
            'filtro': filtro,
            'paginate': 5000,
            'fecha_inicial': startDateI,
            'fecha_final': startDateF,
            'id': id
        }
        setLoading(true)
        axios.post(`https://api-preventa.tabsa.cl/api/historial/transacciones/general`,json_).then(
            test => {
                const respuestita = test.data.data
                setDataForDownload(respuestita);
                setDownloadReady(true);
                setLoading(false)
            }
        );
    };

    return (
        <>
            <header>
                <img src={logo} className="logo"></img>
                <input type="button" className='button2' style={{float: 'right'}} onClick={handleLogout} value="Salir" />
            </header>
            <body>
            <center>
            
            <h1>Transacciones General</h1>
            <br/>
            <br/>
            <br/>

            <table>
                <thead>
                    <tr style={{color: 'white', borderColor: '0px !important'}}>
                        <th style={{textAlign: 'center', minWidth: '120px', maxWidth: '150px'}}>Fecha Inicio</th>
                        <th style={{textAlign: 'center', minWidth: '120px', maxWidth: '150px'}}>Fecha Fin</th>
                        <th style={{textAlign: 'center', minWidth: '120px', maxWidth: '150px'}}>Todas</th>
                        <th style={{textAlign: 'center', minWidth: '120px', maxWidth: '150px'}}>Exitosas</th>
                        <th style={{textAlign: 'center', minWidth: '120px', maxWidth: '150px'}}>Sin Documento</th>
                        <th style={{textAlign: 'center', minWidth: '120px', maxWidth: '150px'}}>Rechazadas</th>
                        <th style={{textAlign: 'center', minWidth: '120px', maxWidth: '150px'}}>ID</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{color: 'white', borderColor: '0px !important'}}>
                        <td style={{textAlign: 'center', minWidth: '120px'}}>
                            <DatePicker selected={startDateI} 
                                        onChange={(date) => setStartDateI(date)}   
                                        type="text"
                                        size="sm"
                                        placeholder="Fecha Inicio"
                                        // disabled={isCheckedID}
                                        minDate={new Date("08-29-2023")}
                                        maxDate={startDateF}
                                        dateFormat="dd/MM/yyyy"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }} 
                            />
                        </td>
                        <td style={{textAlign: 'center', minWidth: '120px'}}>
                            <DatePicker selected={startDateF} 
                                        onChange={(date) => setStartDateF(date)}   
                                        type="text"
                                        size="sm"
                                        placeholder="Fecha Fin"
                                        // disabled={isCheckedID}
                                        minDate={startDateI}
                                        maxDate={new Date()}
                                        dateFormat="dd/MM/yyyy"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }} 
                            />
                        </td>
                        <td style={{textAlign:'center', minWidth: '120px'}}><input style={{transform: 'scale(1.7)', minHeight: '15px', paddingTop:'10px'}} type="checkbox" id="topping" name="topping" checked={isCheckedT} onChange={event => handleChange('t')}/></td>
                        <td style={{textAlign:'center', minWidth: '120px'}}><input style={{transform: 'scale(1.7)', minHeight: '15px', paddingTop:'10px'}} type="checkbox" id="topping" name="topping" checked={isCheckedE} onChange={event => handleChange('e')}/></td>
                        <td style={{textAlign:'center', minWidth: '120px'}}><input style={{transform: 'scale(1.7)', minHeight: '15px', paddingTop:'10px'}} type="checkbox" id="topping" name="topping" checked={isCheckedSD} onChange={event => handleChange('sd')}/></td>
                        <td style={{textAlign: 'center', minWidth: '120px'}}><input style={{transform:'scale(1.7)', minHeight:'15px', paddingTop:'10px'}} type="checkbox" id="topping" name="topping" checked={isCheckedR} onChange={event => handleChange('r')}/></td>
                        <td style={{textAlign: 'center', minWidth: '120px'}}><input style={{transform:'scale(1.7)', minHeight:'15px', paddingTop:'10px'}} type="checkbox" id="topping" name="topping" checked={isCheckedID} onChange={event => handleChange('idc')}/></td>
                        {
                            isCheckedID ? <td style={{textAlign: 'center', minWidth: '120px'}}><input type="number" min="1" style={{decoratio:'none'}} id={id} name={id} hidden={!isCheckedID} onKeyUp={event => handleChangeID(event.target.value)}/></td>
                            :''
                        }
                        {/* <td style={{textAlign: 'center', minWidth: '120px'}}><input type="number" min="1" style={{decoratio:'none'}} id={id} name={id} disabled={isChecked || isCheckedEm || isCheckedEs || isCheckedEx || isCheckedNe}  hidden={!showOtros} onKeyUp={event => handleChangeID(event.target.value)}/></td> */}
                        <td style={{textAlign:'center'}}>&nbsp;
                            <button style={{backgroundColor: 'white', borderRadius: '5px',textDecoration: 'none', color:'black'}} onClick={filtrarCupones} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                    </svg>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            
            {data.length == 0 ?
                s ? <h3 style={{color:"white"}}>Sin resultados</h3> : '': 
                (data.length > 1 ? <>
                    <h3 style={{color:"white"}}>{pagination.total_registros} resultados</h3> 
                    <h4 style={{color:"white"}}>{pagination.pagina_actual*pagination.registros_por_pagina-(pagination.registros_por_pagina-1)} - {pagination.siguiente_pagina_url ? (pagination.registros_por_pagina <= pagination.total_registros ? (pagination.registros_por_pagina * pagination.pagina_actual) : pagination.total_registros) : pagination.total_registros}</h4>
                    <div >
                        {loading ? 
                        <>
                        <div style={{width: "50%",float:"right", marginRight:"-50px", marginTop:"-45px"}}>
                        <button className='excel' style={{}}> 
                        <center>
                        <div class="lds-spinner" style={{marginTop: '-10px', marginLeft: '-10px'}}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </center>
                        </button>
                        </div>
                        </>
                        : 
                        <>
                        <CSVLink
                        data={dataForDownload}
                        filename={'transacciones '+format(new Date(),'yyyy-MM-dd')+'.csv'}
                        className="hidden"
                        separator={';'}
                        ref={csvLink}
                        target="_blank">
                        <div style={{width: "50%",float:"right", marginRight:"-50px", marginTop:"-45px"}}>
                        <button className='excel'> 
                            <a href="javascript:void(0);" onClick={handleDownload} style={{textDecoration: 'none', color: 'black'}}>
                                Descargar
                            </a>
                        </button>
                        </div>
                        </CSVLink>
                        </>
                        }
                    </div>
                    </>
                    : 
                    <>
                    <h3 style={{color:"white"}}>{pagination.total_registros} resultado</h3>
                    <h4 style={{color:"white"}}>{pagination.pagina_actual*pagination.registros_por_pagina-(pagination.registros_por_pagina-1)} - {pagination.siguiente_pagina_url ? (pagination.registros_por_pagina <= pagination.total_registros ? (pagination.registros_por_pagina * pagination.pagina_actual) : pagination.total_registros) : pagination.total_registros}</h4>
                    <div >
                    <CSVLink
                        data={dataForDownload}
                        filename={'reporte '+format(new Date(),'yyyy-MM-dd')+'.csv'}
                        className="hidden"
                        separator={';'}
                        ref={csvLink}
                        target="_blank">
                        <div style={{width: "50%",float:"right", marginRight:"-50px", marginTop:"-45px"}}>
                            <button className='excel' style={{}}> 
                                <a href="javascript:void(0);" onClick={handleDownload} style={{textDecoration: 'none', color: 'black'}}>
                                    Descargar
                                </a>
                            </button>
                        </div>

                    </CSVLink>
                    </div>
                    </>
            )}
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <table className='tbl' style={{opacity: data.length > 0 ? '100%' : '0%'}}>
                <thead>
                    <tr style={{color:'white'}}>
                        <th style={{fontSize: "14px",textAlign:'center', width:'auto', userSelect: 'none'}}>#</th>
                        <th onClick={() => handleSort('Grupo')} style={{fontSize: "14px",textAlign:'center', minWidth:'70px', maxWidth:'100px', userSelect: 'none'}}>Grupo </th>
                        <th onClick={() => handleSort('Monto')} style={{fontSize: "14px",textAlign:'center', minWidth:'auto', maxWidth:'150px', userSelect: 'none'}}>Monto {/*{data.length > 0 ? <u>⇅</u>:''}*/}</th>
                        <th onClick={() => handleSort('Fecha')} style={{fontSize: "14px",textAlign:'center', minWidth:'auto', maxWidth:'150px', userSelect: 'none'}}>Fecha {/*{data.length > 0 ? <u>⇅</u>:''}*/}</th>
                        <th onClick={() => handleSort('Número Tarjeta')} style={{fontSize: "14px",textAlign:'center', minWidth:'auto', maxWidth:'150px', userSelect: 'none'}}>Tarjeta {/*{data.length > 0 ? <u>⇅</u>:''}*/}</th>
                        <th onClick={() => handleSort('Código Autorización')} style={{fontSize: "14px",textAlign:'center', minWidth:'100px', maxWidth:'130px', userSelect: 'none'}}>Código Autorización {/*{data.length > 0 ? <u>⇅</u>:''}*/}</th>
                        <th onClick={() => handleSort('Codigo Respuesta')} style={{fontSize: "14px",textAlign:'center', minWidth:'auto', maxWidth:'150px', userSelect: 'none'}}>Respuesta {/*{data.length > 0 ? <u>⇅</u>:''}*/}</th>
                        <th onClick={() => handleSort('Tipo Documento')} style={{fontSize: "14px",textAlign:'center', minWidth:'auto', maxWidth:'170px', userSelect: 'none'}}>Documento {/*{data.length > 0 ? <u>⇅</u>:''}*/}</th>
                        <th onClick={() => handleSort('Correlativo')} style={{fontSize: "14px",textAlign:'center', minWidth:'auto', maxWidth:'170px', userSelect: 'none'}}>Correlativo {/*{data.length > 0 ? <u>⇅</u>:''}*/}</th>
                        <th onClick={() => handleSort('Identificador')} style={{fontSize: "14px",textAlign:'center', minWidth:'auto', maxWidth:'170px', userSelect: 'none'}}>Identificador {/*{data.length > 0 ? <u>⇅</u>:''}*/}</th>
                        <th onClick={() => handleSort('Correo')} style={{fontSize: "14px",textAlign:'center', minWidth:'auto', maxWidth:'170px', userSelect: 'none'}}>Correo {/*{data.length > 0 ? <u>⇅</u>:''}*/}</th>
                        {nf ? <th>Acción</th> : ''}
                    </tr>
                </thead>
                <tbody className='tblbd'>
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign:'center'}}>
                                    {(index+1)+((pagination.registros_por_pagina*pagination.pagina_actual)-pagination.registros_por_pagina)}
                            </td>
                            {Object.values(item).map((value, i) => <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign:'center'}} key={i}>{value}</td>)}
                            {
                                nf ? 
                                loadingCupon ? 

                                    <td>
                                        <div>
                                        <center>
                                        <div class="lds-spinner" style={{marginTop: '-10px', marginLeft: '-10px'}}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                        </center>
                                        </div>
                                    </td>
                                    : 
                                    nf.nf[index] == true ? <td><button className='excel' onClick={() => generarDocumento(data[index]['Grupo'], index)}>Crear</button></td> : <td></td>
                                    :''
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
            <br/>
            <div style={{color: "white"}} hidden={!data.length}>
                <button disabled={!pagination.pagina_anterior_url} onClick={() => fetchData(pagination.pagina_actual - 1)}>Anterior</button>
                &nbsp;&nbsp;{pagination.pagina_actual} / {Math.ceil(pagination.total_registros/pagination.registros_por_pagina)}&nbsp;&nbsp;
                <button disabled={!pagination.siguiente_pagina_url} onClick={() => fetchData(pagination.pagina_actual + 1)}>Siguiente</button>
            </div>
            <ToastContainer />
            </center>
            <br/>
            </body>
        </>
    );
    function generarDocumento(value, index){
        setLoadingCupon(true)

        console.log('valor de entrada generarDocumento')
        console.log(value)
        const grupo = {
            'id_grupo': value
        }

        console.log('data a enviar')
        console.log(grupo)
        const resp = axios.post('https://api-preventa.tabsa.cl/api/cupones/pago-confirmado',grupo).then(
            response => {

                setLoading(false)
                toast.success('Documento y cupones enviados con éxito.', {
                    position: toast.POSITION.BOTTOM_CENTER
                })
                setLoadingCupon(false)
                fetchData()
            }
        )
    }
}

export default TransaccionesGeneral
import React, { useState } from 'react';
import axios from 'axios';
import '../App.css';
import './Login.css';
import { useNavigate } from "react-router-dom";
import { setUserSession } from '../Utils/Common';
import Cookies from 'universal-cookie';
import logo_ from '../logo.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import show from '../show.png'
import hide from '../hide.png'


var pv;
var url = 'https://ldap.tabsa.cl/api/users/cupones';
var url_pv = 'https://ldap.tabsa.cl/api/auth/pv';

function Login() {

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const username = useFormInput('');
    const password = useFormInput('');
    const [user, setUser] = useState('');
    const cookies = new Cookies();

  
    // handle button click of login form
    function handleChange(checked){
          if(checked){
            pv = true
          }else{
            pv = false
          }
      // }
  }

    const handleLogin = (key) => {

      if((username.value == '' && key == 'Enter') || (key == 'Enter' && password.value == '')){
        toast.error('Ingrese Usuario y Contraseña.', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      }else{
        if(key == 'Enter'){

          setError(null);
          setLoading(true);
          const auth = {
            user : username.value,
            password : password.value,
            sistema : 'preventa'
            // user : 'cargawilliams',
            // password : 'Carwil1'
          }
      
          const headers = { 
          };
          var acceso = pv ? url_pv : url
          axios.post(acceso, auth, { headers }).then(response => {
            if(response.data.auth === 1){
              var respuesta = response.data
              cookies.set('user',respuesta.user, {path: "/"})
              const auth = response.data.auth;
              setLoading(true);
              setUserSession(response.data.token, response.data.user, response.data.auth);
              setUser(response.data.user);
              sessionStorage.setItem('userName',username.value)
              sessionStorage.setItem('tipoUsuario',response.data.tipo)
              if(response.data.tipo === 10){
                navigate('/reportes');
              }else if(response.data.tipo === 11){
                navigate('/reportes/cupones');
              }else{
                navigate('/cupones');
              }
            }else{
              username.value = ''
              password.value = ''
              toast.error('Error de '+response.data.error, {
                position: toast.POSITION.BOTTOM_CENTER
              });
              setLoading(false);
              // window.location.reload();
            }
          })
          // if(getUserSession().auth === 0){
          //   props.history.push('/');
          // }
        }
      }
  
    }
  
    // componentDidMount(){
    //   if (cookies.get('user')){
    //     navigate('/scan')
    //   }
    // }
    
    return (
        <>
            <header className='banner'>
              <img src={logo_} className='logo'></img>
            </header>
            <body>
              <div className='container' >

              {/* <h2 >INICIAR SESIÓN</h2> */}
                  <br /><br />
              <div >
                  <h5>Usuario</h5><br />
                  <input style={{"width":"180px"}} type="text" className='form'  {...username} onKeyDown={e => {handleLogin(e.key)}}/>
              </div>
              <div style={{ 'margin-top': '0px' }} >
                <h5>Contraseña</h5><br />
                <div style={{"background-color":"white","border-radius":"30px","height":"28px"}}>
                  <input style={{"width":"180px"}} className='form' type="password"  {...password} autoComplete="new-password" onKeyDown={e => {handleLogin(e.key)}}/>
                  {/* <button style={{"border-radius":"40px","border":"0px"}} onClick={togglePasswordVisiblity}> */}
                  {/* {passwordShown ? "mostrar" : "ocultar"} */}
                  {/* <img style={{"width":"32px"}} src={passwordShown ? hide : show}></img> */}
                  {/* </button> */}
                </div>
              </div>
              <br></br>
              <span style={{"color":"white"}}>
                <center>    
                  {/* <input style={{"transform":"scale(2)", "min-height":"15px"}} type="checkbox" id="topping" name="topping" onChange={event => handleChange(event.target.checked)}/>  Iniciar con cuenta de PV */}
                </center>
              </span>
              {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
              <input type="button" className='button' value={loading ? 'Ingresando...' : 'Ingresar'} onClick={e => {handleLogin('Enter')}} disabled={loading} /><br />
              </div>
              <ToastContainer />
          </body>
        </>
    );
  }
  const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
  
    const handleChange = e => {
      setValue(e.target.value);
    }
    return {
      value,
      onChange: handleChange
    }
  }
  
  export default Login;
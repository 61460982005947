import { useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../App.css';
import logo from '../logo.png';
import loading from '../LTABSAv2.gif';
import './Reportes.css';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import { getUser, removeUserSession } from '../Utils/Common';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { Modal } from 'react-bootstrap';
import Detalles from './Detalles/Detalles'
import show from '../show-white.png'
import hide from '../hide-white.png'

const Verify= () => {

	const navigate = useNavigate();
	
	useEffect(() => {

		if (!getUser()){
		 navigate("/")   
		}
	},[])
	
}

let es = true;

const ITEMS_PER_PAGE = 20;
function Reportesscw(){
	Verify();

    const [currentPage, setCurrentPage] = useState(1);

    function TableBody() {
        const paginatedResp = sortedResp.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);
        return (
            <>
                {resp ? paginatedResp.map((item, index) => (
                    <tbody className="tblbd">
                        <tr key={item.Cupon}>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', extAlign:'center'}}>
                                    {(index+1)+((ITEMS_PER_PAGE*currentPage)-ITEMS_PER_PAGE)}
                            </td>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                    {item.Cupon}
                            </td>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                    {item.Grupo}
                            </td>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'left'}}>
                                {item.Item}
                            </td>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                    {item.Emitido.substr(8,2)+"-"+item.Emitido.substr(5,3)+item.Emitido.substr(0,4)}
                            </td>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                    {item.Utilizado != null ? item.Utilizado.substr(8,2)+"-"+item.Utilizado.substr(5,3)+item.Utilizado.substr(0,4)+item.Utilizado.substr(10,9):'-'}
                            </td>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                    {item.Nave}
                            </td>
                            <td className='border' style={{fontSize: "14px",borderTop: 'solid 2px white', textAlign: 'center'}}>
                                    {item.Viaje}
                            </td>
                        </tr>
                    </tbody>
                )) : ''}

            </>
        );
    }

	const navigate = useNavigate();

    const cookies = new Cookies();

    const handleLogout = () => {
        cookies.remove('user', {path: "/"})	
        removeUserSession();
        navigate('/');
    }

    const [isLoading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState();
    const [isCheckedID, setIsCheckedID] = useState(false);
    const [isCheckedEm, setIsCheckedEm] = useState(false);
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleCheckboxChange2 = (event) => {
        setIsCheckedEm(event.target.checked);
    };

    const [selectedValue, setSelectedValue] = useState('');
    const [selectedOtros, setSelectedOtros] = useState('0');
    const [id_doc, setIdDoc] = useState(false);
    const [showOtros, setShowOtros] = useState('');
    const [showDocs, setShowDocs] = useState(false);

    // const [startDateI, setStartDateI] = useState(new Date('2023/08/29'));
    const [startDateI, setStartDateI] = useState(() => {
        let date = new Date();
        date.setDate(date.getDate() - 1);
        return date;
    });

    let [startDateFilter, setStartDateFilter] = useState(new Date());
    startDateFilter.setMonth(startDateFilter.getMonth() -1)
    const [startDateF, setStartDateF] = useState(new Date());
    const now = useState(new Date());
    const { identificador } = useParams()
    const url = 'https://api-preventa.tabsa.cl/api/filtro/cuponesscw'
    // const [resp, setResp] = useState([]);
    const [Fe, setFe] = useState(false);
    var filtro = 0;
    var newArreglo = []
    var item_data = 0
    const [dataForDownload, setDataForDownload] = useState([]);
    const [bDownloadReady, setDownloadReady] = useState(false);
    const csvLink = useRef();

    const [detailsShown, setDetailsShown] = useState(false);
    const toggleDetailsVisiblity = () => {
      setDetailsShown(!detailsShown);
    };

    const [resp, setResp] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });
    const sortedResp = [...resp].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });
    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
          direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    function filtrarCupones(){

        const data = {
            'fecha_inicial': startDateI,
            'fecha_final': startDateF,
            'id_nave': selectedValue != 50 ? selectedValue : null,
        } 
        setLoading(true)
        axios.post(url,data).then(
            response => {
                const respuesta = response.data
                setResp(respuesta)
                setFe(true)
                console.log('respuesta')
                console.log(respuesta)
                setLoading(false)
            }
        )
    }
    
    const handleSelectIdDoc = (event) => {
        setIdDoc(event.target.value);
    }

    const [isOpen, setIsOpen] = useState(false);
    
    const abrirModal = (it) => {
        setIsOpen(!isOpen);
        item_data = it

    };
    

    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
    };


    useEffect(() => {
        if (csvLink.current && bDownloadReady) {
          csvLink.current.link.click();
          setDownloadReady(false);
        }
      }, [bDownloadReady]);
    
      // start the download process
      const handleDownload = () => {
        setDataForDownload(resp);
        setDownloadReady(true);
      };


    return (
        <>
            <header>
                <img src={logo} className="logo"></img>
                <input type="button" className='button2' style={{float: 'right'}} onClick={handleLogout} value="Salir" />
            </header>
            <body>

                
                <center>
                    
                <h1>Reportes</h1>
                    <br/>
                    <br/>
                    <br/>
                    <table>
                        <thead>
                            <tr style={{color: 'white', borderColor: '0px !important'}}>
                                <th style={{textAlign: 'center', minWidth: '120px', maxWidth: '150px'}}>Fecha Inicio</th>
                                <th style={{textAlign: 'center', minWidth: '120px', maxWidth: '150px'}}>Fecha Fin</th>
                                <th style={{textAlign: 'center', minWidth: '120px', maxWidth: '150px'}}>Nave</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{color: 'white', borderColor: '0px !important'}}>
                                <td style={{textAlign: 'center', minWidth: '120px'}}>
                                    <DatePicker selected={startDateI} 
                                                onChange={(date) => setStartDateI(date)}   
                                                type="text"
                                                size="sm"
                                                placeholder="Fecha Inicio"
                                                disabled={isCheckedID}
                                                minDate={new Date("08-29-2023")}
                                                maxDate={startDateF}
                                                dateFormat="dd/MM/yyyy"
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }} 
                                    />
                                </td>
                                <td style={{textAlign: 'center', minWidth: '120px'}}>
                                    <DatePicker selected={startDateF} 
                                                onChange={(date) => setStartDateF(date)}   
                                                type="text"
                                                size="sm"
                                                placeholder="Fecha Fin"
                                                disabled={isCheckedID}
                                                minDate={startDateI}
                                                maxDate={new Date()}
                                                dateFormat="dd/MM/yyyy"
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }} 
                                    />
                                </td>
                                <td style={{textAlign: 'center', minWidth: '120px'}}>
                                    <select style={{borderRadius: '3px', height: '20px'}} value={selectedValue} onChange={handleSelectChange}>
                                        <option value="0">Todas</option>
                                        <option value="11">Kawéskar</option>
                                        <option value="13">Kenos</option>
                                        <option value="1">Patagonia</option>
                                        <option value="8">Pionero</option>
                                    </select>
                                </td>
                                <td style={{textAlign:'center'}}>&nbsp;
                                <button style={{backgroundColor: 'white', borderRadius: '5px',textDecoration: 'none', color:'black'}} onClick={filtrarCupones} >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                            </svg>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {isLoading ? 
                        <center>
                        <img src={loading} style={{width:"20%", marginTop: "300px", backgroundColor: "white", borderRadius:"15px"}} ></img>
                        </center>
                     : <>
                    { Fe == true ?
                     resp.length == 0 ?
                        <h3 style={{color:"white"}}>Sin resultados</h3> : (resp.length > 1 ? <h3 style={{color:"white"}}>{resp.length} resultados</h3> : <h3 style={{color:"white"}}>{resp.length} resultado</h3>)
                    :''}
                    { Fe == true ?
                        <>
                        <h4 style={{color:"white"}}>{currentPage*ITEMS_PER_PAGE-(ITEMS_PER_PAGE-1)} - { resp.length >= (ITEMS_PER_PAGE * currentPage) ? ITEMS_PER_PAGE * currentPage : resp.length}</h4>
                        <div >
                            <CSVLink
                                data={dataForDownload}
                                filename={'reporte '+format(new Date(),'yyyy-MM-dd')+'.csv'}
                                className="hidden"
                                separator={';'}
                                ref={csvLink}
                                target="_blank"
                            >
                                <div style={{width: "50%",float:"right", marginRight:"-50px", marginTop:"-45px"}}>
                                   <button className='excel' style={{}}> 
                                        <a href="javascript:void(0);" onClick={handleDownload} style={{textDecoration: 'none', color: 'black'}}>
                                            Descargar
                                        </a>
                                    </button>
                                </div>

                            </CSVLink>
                        </div>
                    <br/><br/><br/><br/>
                    <table className='tbl'>
                        <thead>
                            <tr style={{color:'white'}}>
                                <th style={{fontSize: "14px",textAlign:'center', width:'auto', userSelect: 'none'}}>#</th>
                                <th style={{fontSize: "14px",textAlign:'center', minWidth:'90px', maxWidth:'120px', userSelect: 'none'}}>Cupón </th>
                                <th style={{fontSize: "14px",textAlign:'center', minWidth:'90px', maxWidth:'120px', userSelect: 'none'}}>Grupo </th>
                                <th onClick={() => handleSort('Item')} style={{fontSize: "14px",textAlign:'center', minWidth:'auto', maxWidth:'510px', userSelect: 'none'}}>Ítem {resp.length > 0 ? <u>⇅</u>:''}</th>
                                <th onClick={() => handleSort('Emitido')} style={{fontSize: "14px",textAlign:'center', minWidth:'100px', maxWidth:'170px', userSelect: 'none'}}>Emitido {resp.length > 0 ? <u>⇅</u>:''}</th>
                                <th onClick={() => handleSort('Utilizado')} style={{fontSize: "14px",textAlign:'center', minWidth:'100px', maxWidth:'170px', userSelect: 'none'}}>Utilizado {resp.length > 0 ? <u>⇅</u>:''}</th>
                                <th onClick={() => handleSort('Nave')} style={{fontSize: "14px",textAlign:'center', minWidth:'100px', maxWidth:'150px', userSelect: 'none'}}>Nave {resp.length > 0 ? <u>⇅</u>:''}</th>
                                <th style={{fontSize: "14px",textAlign:'center', minWidth:'100px', maxWidth:'130px', userSelect: 'none'}}>Viaje</th>
                            </tr>
                        </thead>
        
                        <TableBody/>
                    </table>
                    </>

                    :''}
                    {resp.length > 0 ? 

                        <center>
                            <br/>
                            <div style={{color: "white"}}>
                                <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                                    Anterior
                                </button>
                                &nbsp;&nbsp;{currentPage} / {Math.ceil(resp.length/ITEMS_PER_PAGE)}&nbsp;&nbsp;
                                <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(sortedResp.length / ITEMS_PER_PAGE)}>
                                    Siguiente
                                </button>
                            </div>
                        </center>
                    :''}
                    <br/>
                    </>}
                </center>
                <br/>
            </body>
        </>
    )
}

export default Reportesscw
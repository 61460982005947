import { useParams } from 'react-router-dom';
import '../App.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../logo.png';
import './Resultado.css';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import { getUser, removeUserSession } from '../Utils/Common';


const Verify= () => {

	const navigate = useNavigate();
	
	useEffect(() => {
		if (!getUser()){
		 navigate("/")   
		}
	},[])
	
}
const cookies = new Cookies();

function Resultado(){
	Verify();
	const navigate = useNavigate();

    const cookies = new Cookies();

    const handleLogout = () => {
        cookies.remove('user', {path: "/"})	
        removeUserSession();
        navigate('/');
    }
    const [grupos, setGrupos] = useState([]);

    const redirectTransacciones = () => {

        const data = {
            identificador: identificador
        }
        axios.post('https://api-preventa.tabsa.cl/api/grupos/contacto', data).then(
            response => {
                const respuesta = response.data
                setGrupos(respuesta)
                console.log('response grupos')
                console.log(respuesta)
                navigate('/transacciones/'+identificador, {state: {grupos: respuesta}})
            }
        )

    }

    const { identificador } = useParams()
    const url = 'https://api-preventa.tabsa.cl/api/cupones/identificador'
    const [resp, setResp] = useState([]);
    
    var n = 0
    var mail = ''
    const [loading, setLoading] = useState(false);
    var api_response = false
    const correo = useFormInput('');
    const data = {
        identificador : identificador
    }
    let xd = 0
    var newArreglo = []
    var arreglo = []
    var arreglo2 = []
    function handleChange(value, checked){

        if(checked){
            value.utilizado = null
            arreglo[arreglo.length] = value
            xd++
        }else{
            xd--
            var i = 0
            arreglo.forEach(resp => {
                if(resp == value){
                    newArreglo = arreglo
                    newArreglo.splice(i,1)
                    arreglo = newArreglo
                }
                i++
            })
            var n = 0
        }
    }
    
    const handleCupones = (key) => {

        if (key.key === " ") {
            key.preventDefault();
        }

        if(key.key == 'Enter' || key == 'Enter'){
            const headers = { 
            };
            if(arreglo.length == 0){
                toast.error('Debe seleccionar al menos un cupón.', {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }else if(!mail.includes('@') || !mail.includes('.')){
                toast.error('Escribir correo válido.', {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }else{
                setLoading(true)

                arreglo2 = {
                    'data':arreglo,
                    'contacto': mail,
                    'sistema': 'tickets',
                    'usuario': sessionStorage.getItem('userName')
                }

                axios.post('https://api-correo.tabsa.cl/api/cupones',arreglo2).then(
                    response => {
                        const r = response
                        if(r.status == 200){
                            setLoading(false)
                            toast.success('Correo enviado con éxito.', {
                                position: toast.POSITION.BOTTOM_CENTER
                            })
                            setTimeout(function() {
                                navigate('/cupones');
                            }, 5000);
                        }else{
                            setLoading(false)
                            toast.error('Ha ocurrido un error. Intentar nuevamente.', {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                        }
                    }
                )
            }
        }
    }

    useEffect(() => {
        const data = {
            value: identificador,
            filtro: 1
        }
        axios.post(url, data).then(
            response => {
                const respuesta = response.data.data
                setResp(respuesta)
            }
        )
        resp.forEach(resp => {
            n++
        });
	},[])

    function setCorreo(correo){
        mail = correo 
    };

    return (
        <>
            <div>
                <img src={logo} style={{float: 'left'}} className="logo"></img>
                <input type="button" className='button2' style={{float: 'right'}} onClick={handleLogout} value="Salir" />
            </div>
            <br/>
            <body style={{marginTop: '0px'}}>
                {
                    sessionStorage.getItem('tipoUsuario') >= 3 ?
                    <>
                        <input type="button" className='button' id="transacciones" style={{marginLeft: '65%', marginTop: '-240px'}} onClick={redirectTransacciones} value="Transacciones" />
                        <br/><br/><br/><br/><br/><br/>
                    </>:''
                }
                <center>
                    <table style={{marginTop: '140px !important'}}>
                        <thead>
                            <tr style={{color: 'white'}}>
                                <th style={{textAlign: 'center'}}>Ítem</th>
                                <th style={{textAlign: 'center'}}>Seleccionar</th>
                                <th style={{textAlign: 'center'}}>Adquirido</th>
                                <th style={{textAlign: 'center'}}>Utilizado</th>
                                <th style={{textAlign: 'center'}}>Expirado</th>
                                {
                                    sessionStorage.getItem('tipoUsuario') == 0 ||sessionStorage.getItem('tipoUsuario') == 2 ||sessionStorage.getItem('tipoUsuario') == 3 ||sessionStorage.getItem('tipoUsuario') == 5 ?
                                    <th style={{textAlign: 'center'}}>Abrir</th>
                                    : ''
                                }
                                {
                                    sessionStorage.getItem('tipoUsuario') == 0 || sessionStorage.getItem('tipoUsuario') == 1  || sessionStorage.getItem('userName') == 'devtest' ?
                                    <th style={{textAlign: 'center'}}>Copiar Código</th>
                                    :''
                                }

                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(resp).map((i) => (
                                <tr key={i}>
                                    <td className='border' style={{borderTop: 'solid 2px white', color: 'white'}}>
                                        {resp[i].tarifa.nombre_tarifa}
                                    </td>
                                    <td className='border' style={{borderTop: 'solid 2px white'}}>
                                        <center>
                                            <input style={{transform: 'scale(2)', minHeight: '40px'}} hidden={resp[i].timestamp_utilizacion == null && resp[i].estado == 0 && resp[i].expirado == 'No' ? false:true} type="checkbox" id="topping" name="topping" value={resp[i]} onChange={event => handleChange(resp[i], event.target.checked)}/>
                                            <p hidden={resp[i].timestamp_utilizacion != null || resp[i].estado != 0 || resp[i].expirado != 'No' ? false:true} style={{color: 'white'}}>-</p>
                                        </center>
                                    </td>
                                    <td className='border' style={{borderTop: 'solid 2px white', color: 'white'}}>
                                        {resp[i].adquirido}
                                    </td>
                                    <td className='border' style={{borderTop: 'solid 2px white', color: 'white'}}>
                                        <center>
                                            {resp[i].timestamp_utilizacion != null ? resp[i].timestamp_utilizacion.substr(0,19):'-'}
                                        </center>
                                    </td>
                                    <td className='border' style={{borderTop: 'solid 2px white', color: 'white'}}>
                                        <center>
                                            {resp[i].expirado}
                                        </center>
                                    </td>
                                    {
                                    sessionStorage.getItem('tipoUsuario') == 0 || sessionStorage.getItem('tipoUsuario') == 2 ||sessionStorage.getItem('tipoUsuario') == 3 ||sessionStorage.getItem('tipoUsuario') == 5 ?
                                    <td className='border' style={{borderTop: 'solid 2px white', color: 'white'}}>
                                        <center>
                                            <div>
                                            {resp[i].timestamp_utilizacion == null && resp[i].estado == 0 && resp[i].expirado == 'No' ? 
                                                <a href={resp[i]['url']} target="_blank">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-file-earmark-minus-fill" viewBox="0 0 16 16" style={{color: '#facd00'}}>
                                                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM6 8.5h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1 0-1z"/>
                                                    </svg>
                                                </a>
                                            :'-'}
                                            </div>
                                        </center>
                                    </td>
                                    : ''                                    
                                    }
                                    {
                                    sessionStorage.getItem('tipoUsuario') == 0 || sessionStorage.getItem('tipoUsuario') == 1 || sessionStorage.getItem('userName') == 'devtest' ?
                                    <td className='border' style={{borderTop: 'solid 2px white', color: 'white'}}>
                                        <center>
                                            <div>
                                                {
                                                resp[i].expirado == 'No' ? 
                                                <a href="javascript:void(0);" onClick={() => { copyContent(resp[i]['seed']+'&'+resp[i]['id_tarifa']+'&'+identificador+'&'+resp[i]['id_cupon']+'!')}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-file-earmark-minus-fill" viewBox="0 0 48 48" style={{color: '#facd00'}}>
                                                        <path d="M32 2H8C5.79 2 4 3.79 4 6v28h4V6h24V2zm6 8H16c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h22c2.21 0 4-1.79 4-4V14c0-2.21-1.79-4-4-4zm0 32H16V14h22v28z"></path>
                                                    </svg>
                                                </a>
                                                : '-'
                                                }
                                            </div>
                                        </center>
                                    </td>
                                    : ''
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <br/>
                    <div style={{marginTop: '30px !important'}}>
                        <p style={{color: 'white', fontSize:'20px'}}>Enviar a</p>
                        <input type="email" onKeyDown={event => handleCupones(event)} className="form" onChange={event => setCorreo(event.target.value)} value={mail.value} placeholder="ejemplo@prueba.com" onPaste={(e: any) => {e.preventDefault(); return false;}} style={{width: '350px'}}></input>
                        {/* <input type="email" onKeyDown={event => handleKeyDown(event)} onChange={setCorreo()} value={mail} placeholder="ejemplo@prueba.com" onPaste={(e: any) => {e.preventDefault(); return false;}}></input> */}
                        <br/>
                        <br/>
                        <input type="button" hidden={ (arreglo.length > 0 && mail.value.length > 0)?true:false} onChange={event => setCorreo(event.target.value)} className='button' value={loading ? 'Enviando...' : 'Enviar'} onClick={event => handleCupones('Enter')} disabled={loading} /><br />
                        <br/>
                    </div>
                    <ToastContainer />

                </center>
            </body>
        </>
    )
}
function copyContent(url){
    navigator.clipboard.writeText(url)
    toast.success('Código copiado.', {
        position: toast.POSITION.BOTTOM_CENTER
    })
}
const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
  
    const handleChange = e => {
      setValue(e.target.value);
    }
    return {
      value,
      onChange: handleChange
    }
}

export default Resultado